<template>
  <div class="min-h-screen bg-background flex flex-col justify-center py-12 px-6 sm:px-6 lg:px-8 dark:bg-backgroundDark">
      <div class="mx-auto w-full sm:max-w-md">
        <success :message="alertMessage" v-if="showSuccess" />
                <danger :message="alertMessage" v-if="showDanger" />  
        <div class="bg-primary p-8 pt-5 shadow rounded-lg dark:bg-primaryDark">
     
        
          <div class="pb-4">
            <p class="text-center text-3xl font-bold text-gray-900 dark:text-white">
              Forgot password
            </p>
            <!-- <p class="text-l text-gray-500 dark:text-white text-sm">Enter your email to reset your password</p> -->
          </div>
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700 dark:text-white">
              Email address
            </label>
            <div class="mt-1 ">
              <input v-model="state.email" id="email" name="email" type="email" autocomplete="email" required=""
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white dark:bg-primaryDark" />
            </div>
            <div :class="{ error: v$.email.$errors.length }">
                <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                  <div class="error-msg text-red-600" v-if="error.$validator==='required'">Email is required</div>
                  <div class="error-msg text-red-600" v-if="error.$validator==='email'">Enter valid email address</div>
                </div>
              </div>
          </div>
          <div class="mt-3">
            <button @click="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-backgroundDark dark:text-white">Send
              recovery email</button>
          </div>
        </div>
      </div>

      <!-- <div v-else
        class="mx-auto w-full max-w-sm lg:w-96">
        <p class="mt-6 sm:text-3xl text-2xl font-bold text-gray-900 dark:text-white">
          Forgot Password
        </p>
        <p class="text-l dark:text-white">{{ state.message }}</p>
      </div> -->
  </div>
</template>

<script>
import { reactive } from 'vue'
import { useStore } from "vuex";
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import success from '@/components/widgets/alertMessage/success'
import danger from '@/components/widgets/alertMessage/danger'

export default {
  name: "ForgotPassword",
  components: { success,danger },
  data() {
        return {
          alertMessage: "",
          showSuccess:false,
          showDanger:false
        }
    },
  setup() {
    // const email = ref("")
    const store = useStore()

    const state = reactive({
      email: '',
   
    })
    const rules = {
      email: { required, email },
    }
    const v$ = useVuelidate(rules, state, {$lazy: true})
    
    return {
      v$,
      store,
      state,      
    }
  },methods: {
        async submit() {
          this.showSuccess=false;
          this.showDanger=false;
          this.v$.$touch()
          if (this.v$.$error) return
          try {
            await this.$store.dispatch("ForgotPassword", this.state.email)
                .then(response => {
                  if ( response.success === true || response.success === 'true' ) {
                     
                        this.alertMessage="Password reset link sent. Please check your email to set a new password.";
                        this.showSuccess=true;
                    }else{
                      console.log(response.message)
                      this.alertMessage=response.message
                      this.showDanger=true;
                      }
                  }).catch(error => {
                    this.alertMessage=error
                    this.showDanger=true;
                  });
            } catch (error) {
                this.alertMessage=error
                this.showDanger=true;
            }
           
        this.state.email='';
        this.v$.$reset()
      }
    }

}
</script>
